/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCZNPELzYZhXT5TP9lZOeWTesDbMlnyPvU",
  "appId": "1:460316807209:web:3cc062d094d850bb72cc38",
  "authDomain": "schooldog-i-muscogee-ga.firebaseapp.com",
  "measurementId": "G-MG9JX4KFGE",
  "messagingSenderId": "460316807209",
  "project": "schooldog-i-muscogee-ga",
  "projectId": "schooldog-i-muscogee-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-muscogee-ga.appspot.com"
}
